@font-face {
  font-family: 'Neue Montreal';
  src: url('assets/fonts/neue-montreal/PPNeueMontreal-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Montreal';
  src: url('assets/fonts/neue-montreal/PPNeueMontreal-Bold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Montreal';
  src: url('assets/fonts/neue-montreal/PPNeueMontreal-Thin.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Neue Montreal';
  src: url('assets/fonts/neue-montreal/PPNeueMontreal-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Neue Montreal';
  src: url('assets/fonts/neue-montreal/PPNeueMontreal-BoldItalic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Neue Montreal';
  src: url('assets/fonts/neue-montreal/PPNeueMontreal-Italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Neue Montreal';
  src: url('assets/fonts/neue-montreal/PPNeueMontreal-ThinItalic.ttf') format('ttf');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Menlo Regular';
  font-style: normal;
  font-weight: normal;
  src:
    local('Menlo Regular'),
    url('assets/fonts/menlo/Menlo-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/inter/Inter-VariableFont_opsz-wght.ttf');
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/inter/Inter-Italic-VariableFont_opsz-wght.ttf');
  font-style: italic;
}
@font-face {
  font-family: 'Geist';
  src: url('assets//fonts/geist/Geist-VariableFont-wght.ttf');
}

.dashboard-header .navbar {
  margin: 0;
  padding: 0;
}
.dashboard-header .navbar .container-fluid {
  margin: 0;
  padding: 0;
}
.dashboard-card {
  gap: 8px;
}
.dashboard-card .card {
  height: 210px;
  border-radius: 10px;
}
.dashboard-card .card .card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dashboard-card .card-footer {
  border: none;
  padding: 0;
  background: none;
}
.dashboard-card .card-footer .count-number {
  font-size: 36px;
  font-weight: 600;
  line-height: 1.2;
}
.dashboard-card .card-footer .card-title {
  color: #374151;
  margin-bottom: 0;
}
.card.bg-primary {
  background-color: #e4f2ff !important;
  border: none;
}
.card.bg-primary .card-footer .count-number {
  color: #3a86ff;
}
.card.bg-secondary .card-footer .count-number {
  color: #00d3f7;
}
.card.bg-info .card-footer .count-number {
  color: #513aff;
}
.card.bg-warning .card-footer .count-number {
  color: #ff3a86;
}
.card.bg-secondary {
  background-color: #d9faff !important;
  border: none;
}
.card.bg-info {
  background-color: #efe8ff !important;
  border: none;
}
.card.bg-warning {
  background-color: #ffe5ee !important;
  border: none;
}

.mat-mdc-dialog-actions {
  justify-content: center !important;
}

.iti {
  display: block;
}
.iti input,
.iti input[type='tel'],
.iti input[type='text'] {
  width: 100% !important;
  padding-top: 13px;
  padding-bottom: 13px;
  border: none;
  background: #f3f4f6;
  border-radius: 8px;
}
.iti input#country-search-box:focus-visible,
.iti input:focus-visible,
.iti input[type='tel']:focus-visible,
.iti input[type='text']:focus-visible {
  outline: 0;
}
.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background: 0 0;
}
ngx-intl-tel-input .iti.iti--allow-dropdown {
  display: flex;
  background: #f3f4f6;
  border-radius: 8px;
}
ngx-intl-tel-input .iti.iti--allow-dropdown .iti__flag-container {
  border-radius: 8px;
  position: static;
}
ngx-intl-tel-input .iti.iti--allow-dropdown .country-dropdown {
  width: 100%;
  background: #f5f5f5;
  padding: 10px;
}
ngx-intl-tel-input .iti.iti--allow-dropdown input.custom {
  background: 0 0;
  padding: 0 !important;
  height: 50px;
}
ngx-intl-tel-input .iti.iti--allow-dropdown .country-dropdown .search-container input {
  width: 100% !important;
  background: #fff;
  border-radius: 5px;
  margin-bottom: 5px !important;
  border: 2px solid #eee;
  height: 44px;
  padding-left: 12px !important;
  font-size: 0.875rem;
}
.country-dropdown .iti__country-list {
  max-height: 155px;
  box-shadow: none;
  overflow: scroll;
}
.iti__country-list li.iti__country {
  font-size: 13px;
}

:root {
  --neutral1: #000106;
  --neutral2: #000209;
  --neutral3: #00020c;
  --neutral4: #404149;
  --neutral5: #808186;
  --neutral6: #bfc0c2;
  --neutral7: #ffffff;
  --blue1: #00197a;
  --blue1-rgb: 0, 25, 122;
  --blue2: #40539b;
  --blue3: #808cbd;
  --blue4: #bfc6de;
  --blue-secondary: #0044ff;
  --blue-dark: #253ea7;
  --brand100: #cfeaff;
  --brand500: #1358ff;
  --brand600: #0044ff;
  --brand700: #0044ff;
  --brand950: #00197a;
  --turquoise1-rgb: 0, 213, 211;
  --turquoise1: #00d5d3;
  --turquoise2: #40e0de;
  --turquoise3: #80eae9;
  --turquoise4: #bff5f4;
  --background-color1: #00d5d3;
  --background: #eff1f2;
  font-size: 16px;
  --max-width: 929px;
  --gray25: #fcfcfd;
  --gray50: #f8fafc;
  --gray100: #f1f5f9;
  --gray200: #e2e8f0;
  --gray300: #cbd5e1;
  --gray400: #94a3b8;
  --gray500: #64748b;
  --gray600: #475569;
  --gray700: #334155;
  --gray800: #1e293b;
  --gray900: #0f172a;
  --gray950: #0c111d;
  --success50: #f0fdf4;
  --success200: #bbf7d0;
  --success500: #22c55e;
  --success600: #16a34a;
  --success700: #15803d;
  --bs-body-font-weight: 500 !important;
  --error500: #ef4444;
}

label {
  font-size: 1rem;
  color: #374151;
  width: 100%;
}

.lty-btn {
  border-radius: 33px;
  padding: 0.5rem 1.5rem 0.5rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lty-btn-alternate {
  padding: 12px 18px 12px 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
.lty-btn-alternate.btn-sm {
  padding: 8px 12px;
}

.lty-btn-alternate.lty-btn-primary {
  background-color: var(--brand600);
  border: 1px solid var(--brand700);
  color: white;
}
.lty-btn-alternate.lty-btn-success {
  background-color: white;
  border: 1px solid var(--success600);
  color: var(--success600);
}

.lty-btn-alternate.lty-btn-primary:disabled {
  background-color: #93b0ff;
  border: 1px solid #93b0ff;
  color: white;
}

.lty-btn.lty-btn-primary {
  background-color: var(--blue1);
  color: var(--neutral7);
  border: 3px solid var(--blue1);
}

.lty-btn.lty-btn-secondary {
  background-color: var(--blue-secondary);
  color: var(--neutral7);
  border: 3px solid var(--blue-secondary);
  padding: 8px 12px;
  border-radius: 8px;
}

.lty-btn.lty-btn-danger {
  background-color: rgb(187, 0, 0);
  color: white;
  border: 3px solid rgb(187, 0, 0);
}
.lty-btn.lty-btn-danger:disabled {
  background-color: rgb(255, 148, 148);
  color: white;
  border: 3px solid rgb(255, 148, 148);
  cursor: not-allowed;
}

.lty-btn-success.fill {
  background-color: var(--success700);
  border: 1px solid var(--success600);
  color: white;
}

.lty-btn.lty-btn-outline {
  background-color: transparent;
  color: var(--blue1);
  border: 3px solid var(--blue1);
}

.lty-btn-white {
  border: 1px solid var(--gray300);
  background-color: white;
  color: var(--gray800);
}
.lty-btn-white:disabled {
  background-color: rgb(242, 242, 242);
}

.lty-btn.lty-btn-outline-black {
  background-color: transparent;
  color: var(--neutral1);
  border: 3px solid var(--neutral1);
}

.lty-btn.lty-btn-primary:disabled {
  background-color: var(--blue3);
  color: var(--neutral7);
  border: 3px solid var(--blue3);
}

.invalid {
  color: red;
  font-size: 0.75rem;
  margin-top: 5px;
}

.lty-btn.lty-btn-outline:disabled {
  color: var(--neutral6);
  border: 3px solid var(--neutral6);
}

.flex-1 {
  flex: 1;
}

.lty-btn.lty-btn-outline-black:disabled {
  color: var(--neutral6);
  border: 3px solid var(--neutral6);
}
select.form-control {
  background: #f3f4f6 url(/assets/img/downarrow.png) no-repeat center right;
  cursor: pointer;
}

select.form-control.alternate {
  background: white url(/assets/img/downarrow.png) no-repeat center right;
  border: 1px solid var(--gray300);
}

input.lty-input {
  background: transparent;
  border: none;
}

input.lty-input:focus {
  outline: none;
}

.lty-input-xl {
  font-size: 5rem;
}

.color-neutral1 {
  color: var(--neutral1);
}

.color-neutral2 {
  color: var(--neutral2);
}

.color-neutral3 {
  color: var(--neutral3);
}

.color-neutral4 {
  color: var(--neutral4);
}

.color-neutral5 {
  color: var(--neutral5);
}

.color-neutral6 {
  color: var(--neutral6);
}

.color-neutral7 {
  color: var(--neutral7);
}

.color-blue1 {
  color: var(--blue1);
}

.color-blue2 {
  color: var(--blue2);
}

.color-blue3 {
  color: var(--blue3);
}

.color-blue4 {
  color: var(--blue4);
}

h1 {
  font-size: 2.5rem;
  font-weight: 500;
}

h2 {
  font-size: 2rem;
  font-weight: 500;
}

h3 {
  font-size: 1.75rem;
  font-weight: 500;
}

h4 {
  font-size: 1.5rem;
  font-weight: 500;
}

h5 {
  font-size: 1.25rem;
  font-weight: 500;
}

h6 {
  font-size: 1rem;
  font-weight: 500;
}

.btn-empty {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}

.lty_form_sidebar {
  background-color: var(--gray50);
  padding: 1.5rem;
  width: 20%;
  display: flex;
  flex-direction: column;
}

.lty_form_body {
  width: 80%;
  overflow: auto;
  display: flex;
  padding-top: 75px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: white;
}

.lty_form_body > router-outlet + * {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lty_form_page_container {
  height: stretch;
  height: 100%;
  height: moz-available;
  height: -webkit-stretch;
}
.lty_form_page_container > * {
  display: flex;
  flex-direction: column;
}

.btn_next,
.btn_check {
  position: relative;
  padding: 0.75rem 1.5rem 0.75rem 1.5rem;
}
.btn_next::after {
  content: '\2192';
  margin-left: 10px;
  font-size: inherit;
}

.btn_check::before {
  content: '\2713';
  margin-right: 10px;
  font-size: inherit;
  color: inherit;
}

.btn_skip::before {
  content: 'x';
  font-size: 1.5rem;
  font-weight: 400;
  margin-right: 10px;
}

.btn_plus:after {
  content: '\002B';
  font-weight: 400;
  margin-left: 10px;
}

.text_btn {
  outline: none;
  border: none;
  background: transparent;
}

.icon_btn {
  background: var(--background);
  border-radius: 50%;
  outline: none;
  border: none;
  height: 43px;
  width: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lty_form_body .back_btn {
  align-self: start;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lty_form_module_container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.stacked-elements {
  position: relative;
}

.stacked-elements > div.stack-element {
  padding: 12px 16px 12px 16px;
  border-radius: 11px;
  width: 100%;
  color: white;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.stack-element.transition {
  transform: rotate(-3deg) translateY(-15px);
}

.highlighted_text {
  color: var(--blue1);
  font-weight: 700;
}
.highlighted_text_special {
  color: var(--turquoise1);
  font-weight: 700;
}

.main_form_container > .lty_form_body .form_page {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 145px);
  overflow: auto;
  padding: 0 80px;
}

.flow_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
  max-width: var(--max-width);
  height: 100%;
  margin-top: 16px;
}
.flow_form .back_btn {
  display: flex;
  margin-right: auto;
}
.flow_form > div,
.flow_form > h1 {
  width: 100%;
  margin: 1rem 0;
}

.flow_form input:not([type='radio'], [type='checkbox']) {
  background-color: var(--background) !important;
}

input[type='radio'] {
  cursor: pointer;
}

.flow_form input::placeholder {
  color: var(--neutral6);
}

.flow_form label,
.form_page label {
  color: var(--neutral3);
  font-size: 14px;
  line-height: 24px;
}

.flow_form .auth_subtitle {
  text-align: center;
  margin-bottom: 0.75rem;
  color: var(--neutral6);
}

.flow_form .auth_title {
  text-align: center;
  font-size: 2.5rem;
  color: #153242;
}

.insurance_form .insurance_logo {
  height: 20px;
  width: auto;
}
.insurance_form .health_question {
  color: var(--neutral3);
  font-size: 1rem;
  margin-bottom: 1rem;
  display: block;
  font-weight: 500;
}

.insurance_form label {
  color: var(--neutral3);
  font-size: 14px;
  font-weight: 500;
}

.insurance_form h1.title {
  text-align: center;
  color: #153242;
  font-size: 2.5rem;
  margin-top: 0.5rem;
}

.insurance_form .subtitle {
  text-align: center;
  color: var(--neutral6);
  font-size: 1.5rem;
}

.insurance_form .form_body .section_title {
  color: var(--neutral3);
  font-size: 1.5rem;
}

.insurance_form .form_body > * {
  margin: 2rem 0;
}

.insurance_form .form_body div.row > div:first-child {
  padding-left: 0;
}
.insurance_form .form_body div.row > div:last-child {
  padding-right: 0;
}
.insurance_form .form_body div.row > div:only-child {
  padding-right: 0;
  padding-left: 0;
}
.details_text {
  color: #868c98;
}
.end-component-container {
  height: 100vh;
  max-height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: var(--background);
}
@media screen and (min-width: 1600px) {
  .lty_form_body {
    padding-top: 150px;
  }
  .main_form_container > .lty_form_body .form_page {
    height: calc(100vh - 220px);
  }
}
@media screen and (min-width: 1200px) {
  .lty_form_body {
    padding-top: 50px;
  }
}
.recap-container {
  height: 100vh;
  display: block;
}
.signature-canvas {
  background-color: white;
}
@media screen and (min-width: 769px) {
  .main_form_container .form_stepper {
    min-height: calc(100vh - 360px);
  }
}
.main_form_container .form_back_btn {
  flex-grow: 1;
}
.main_form_container .form_back_btn,
.form_back_btn {
  display: flex;
  align-items: center;
}
.form_back_btn > span {
  margin-left: 16px;
}

input::-webkit-datetime-edit-day-field:focus,
input::-webkit-datetime-edit-month-field:focus,
input::-webkit-datetime-edit-year-field:focus {
  background-color: transparent;
  color: var(--blue1);
  outline: none;
}
.form_page label {
  font-weight: 500;
}

.advantage-element {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 253px;
}

.advantage-number {
  border-radius: 12px;
  padding: 10px;
  background-color: #e6fffc;
  color: #00d5d3;
  font-size: 14px;
  width: 44px;
  height: 44px;
  text-align: center;
  margin-bottom: 8px;
}
.advantage-title {
  font-size: 14px;
  color: var(--neutral3);
  margin-bottom: 4px;
  font-weight: 500;
}
.advantage-text {
  font-size: 14px;
  font-weight: 400;
  color: var(--neutral4);
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

@media screen and (max-width: 719px) {
  .advantage-element {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}

.ngx-otp-input {
  width: 44px !important;
  height: 44px !important;
  border: 1px solid #e5e7eb !important;
  font-size: 1rem !important;
}
.password-group {
  position: relative;
}
.password-group i {
  position: absolute;
  right: 16px;
  top: 9px;
  font-size: 1.313rem;
}

#updateEmailOtp form.ngx-otp-input-container,
#updatephoneOtp form.ngx-otp-input-container {
  justify-content: center !important;
}

.lty_form_body.building-flow form {
  max-width: 1024px;
}
.main-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.panel {
  background-color: #f9f9f9;
  padding: 24px 20px 24px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
}
.panel .text-content {
  display: flex;
  flex-direction: column;
}
.panel .text-content .main-text {
  color: #00020c;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.panel .text-content .sub-text {
  color: #808186;
  font-size: 0.875rem;
  font-weight: 500;
  margin-top: 4px;
}

.lty-nav {
  width: 100%;
  border-bottom: 1px solid #e4e7ec;
  gap: 1rem;
}

.lty-nav .lty-nav-btn {
  color: #667085;
  font-weight: 500;
  padding: 0px 4px 16px 4px;
}
.lty-nav .lty-nav-btn.active {
  color: var(--blue1) !important;
  font-weight: 500;
  border: none;
  border-bottom: 2px solid var(--blue1) !important;
}

.lty-badge {
  padding: 1px 8px;
  border-radius: 70px;
  color: #898989;
  background-color: #78787852;
  display: flex;
  text-align: center;
}
.lty-badge.lty-badge-success {
  background-color: #007a1b52;
  color: #003d0d;
}
.lty-badge.lty-badge-warning {
  background-color: #ffdac2;
  color: #b56a38;
}
.lty-badge.clock-badge .icon {
  content: url(assets/img/clock.svg);
  margin-right: 0.5rem;
}
.lty-badge.check-badge .icon {
  content: url(assets/img/check.svg);
  margin-right: 0.5rem;
}

.lty-badge.check-badge .icon,
.lty-badge.clock-badge .icon {
  filter: invert(58%) sepia(0%) saturate(41%) hue-rotate(224deg) brightness(94%) contrast(88%);
}

.lty-badge-warning.clock-badge .icon,
.lty-badge-warning.check-badge .icon {
  filter: invert(45%) sepia(14%) saturate(2023%) hue-rotate(341deg) brightness(99%) contrast(87%);
}
.lty-badge-success.clock-badge .icon {
  filter: invert(10%) sepia(76%) saturate(2648%) hue-rotate(132deg) brightness(101%) contrast(105%);
}

.lty-dashboard-header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.lty-dashboard-header h1 {
  font-size: 1.75rem;
  color: #0a0d14;
}
.navbar-v2 {
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 1000;
}
.navbar-v2 .active-nav,
.navbar-v2 .active-nav * {
  color: var(--blue1) !important;
}
body {
  margin: 0;
  font-family: 'Neue Montreal', sans-serif;
}
.help-text-submit-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.form-control {
  padding: 0.8rem 1rem;
  background-color: #f3f4f6;
  border: none;
  border-radius: 8px;
  color: #6b7280;
}
.form-control:focus {
  color: #212529;
  background-color: #f3f4f6;
  border-color: var(--bs-primary);
  outline: 0;
  box-shadow: none;
}
@media screen and (min-width: 769px) {
  .lty_form_body {
    position: relative;
  }
  .lty_form_body .back_btn {
    position: absolute;
    left: 50px;
    margin-top: -50px;
  }
  .main_form_container {
    min-height: calc(100vh - 50px);
    height: auto !important;
  }
}

@media screen and (max-width: 768px) {
  :root {
    font-size: 14px;
  }
  .main_form_container {
    flex-direction: column;
  }

  .lty_form_body {
    width: 100%;
    padding-top: 16px;
  }
  .main_form_container > .lty_form_body .form_page,
  .lty_form_body .form_page,
  .main_form_container > .lty_form_body .flow_form {
    padding: 0 24px;
  }

  .mobile_hidden {
    display: none !important;
  }
  .lty_form_sidebar {
    width: 100%;
    background-color: var(--gray50);
    padding: 1.5rem 15px;
  }

  .lty_form_body .back_btn {
    position: absolute;
    top: 72px;
    left: 15px;
  }

  .lty_form_body h1,
  .lty_form_body h2,
  .lty_form_body h3,
  .lty_form_body h4,
  .lty_form_body h5,
  .lty_form_body h6 {
    text-align: center;
  }

  .insurance_form .form_body div.row > div {
    padding-left: 0;
    padding-right: 0;
  }

  .insurance_form .form_body div.row > div:not(:last-child) {
    margin-bottom: 24px;
  }

  .lty_form_body .lty-btn-primary {
    margin-top: auto !important;
    margin-bottom: 32px !important;
    width: 100%;
  }
  .lty-form-body app-help-text {
    margin-top: 20px !important;
  }

  .help-text-submit-div app-help-text {
    margin: 18px 16px 0 !important;
  }
  .main_form_container > .lty_form_body .form_page,
  .main_form_container > .lty_form_body .flow_form {
    min-height: calc(100vh - 109px);
  }
}

@media screen and (min-width: 769px) {
  .w-md-75 {
    width: 75% !important;
  }
  .help-text-submit-div .lty-btn-primary {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
}
.recap-container .advantages-banner {
  width: 160vw;
  height: 1000px;
  position: absolute;
  background-color: #00197a;
  border-radius: 0 0 100% 100%;
  top: -800px;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.recap-container .banner-container {
  position: relative;
  height: 200px;
  overflow: hidden;
}

.recap-container .banner-title {
  font-size: 2.5rem;
  color: white;
  font-weight: 700;
  bottom: 50px;
  position: absolute;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.recap-container .centralisation-advantage {
  margin-top: 95px;
  max-width: 929px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 44px;
}

.recap-container .advantage-element {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 253px;
}

.recap-container .advantage-number {
  border-radius: 12px;
  padding: 10px;
  background-color: #e6fffc;
  color: #00d5d3;
  font-size: 14px;
  width: 44px;
  height: 44px;
  text-align: center;
  margin-bottom: 8px;
}
.recap-container .advantage-title {
  font-size: 14px;
  color: var(--neutral3);
  margin-bottom: 4px;
  font-weight: 500;
}
.recap-container .advantage-text {
  font-size: 14px;
  font-weight: 400;
  color: var(--neutral4);
}
.recap-container .buttons-div {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 75px;
  margin-bottom: 25px;
  justify-content: center;
}
.recap-container .buttons-div-container {
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 991px) {
  .recap-container .buttons-div-container {
    margin-top: 100px;
  }
  .recap-container .buttons-div {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    margin-bottom: 0;
    padding: 16px;
  }
  .recap-container .buttons-div button {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 719px) {
  .recap-container .advantages-banner {
    width: 100vw;
    height: 64px;
    top: 0;
    position: absolute;
    border-radius: 0 0 15% 15%;
  }

  .recap-container .banner-title {
    bottom: unset;
    top: 12px;
    font-size: 18px;
  }
  .recap-container .banner-title img {
    height: 25px;
    width: auto;
  }

  .recap-container .banner-container {
    position: relative;
    height: 52px;
    border-radius: 0 0 25% 25%;
  }

  .recap-container .centralisation-advantage {
    margin-top: 40px;
    margin-left: 24px;
    margin-right: 24px;
  }

  .recap-container .advantage-element {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .recap-container button {
    width: 90%;
    justify-content: center;
    margin-bottom: 25px;
  }
}

/*File upload styling*/
.file-input-wrapper {
  position: relative;
  width: 100%;
  padding: 0 0.75rem;
}

.file-input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}

.custom-file-input {
  width: 100%;
  height: 100px;
  border: 2px dashed var(--neutral2);
  background-color: var(--background);
  text-align: center;
  line-height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.custom-file-input label {
  font-size: 14px;
  color: var(--neutral6);
}

.upload-icon {
  opacity: 0.5;
  vertical-align: middle;
  margin-right: 5px;
}

.file-badge {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 16px;
  background-color: var(--background);
  align-self: start;
  margin-left: 0.5rem;
}
.file-badge .remove {
  cursor: pointer;
  margin-left: 1rem;
  background-color: rgb(128, 128, 128);
  padding: 0 0.4rem;
  border-radius: 100%;
  color: white;
}
.lty-btn-transparent {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
.welcome-text {
  margin-bottom: 16px;
  color: #0a0d14;
  font-size: 1.75rem;
  font-weight: 500;
}
.dashboard-cards {
  margin-bottom: 32px;
}
.dashboard-cards .dashboard-card {
  border: 1px solid var(--stroke-soft-200, #e2e4e9);
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
}
.dashboard-cards .dashboard-card-footer {
  font-size: 2.5rem;
  color: var(--neutral4);
  font-weight: 500;
}
.dashboard-cards a {
  text-decoration: none;
}
.dashboard-cards .dashboard-card-title {
  display: flex;
  font-size: 1rem;
  color: #667085;
  align-items: center;
  gap: 16px;
  flex-direction: column;
  justify-content: center;
}
.dashboard-card-title > span {
  font-family: 'Neue Montreal', sans-serif;
  color: var(--gray900);
  font-size: 18px;
  font-weight: 700;
}
.dashboard-cards {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}
.dashboard-cards .dashboard-card-icon {
  height: 24px;
  width: 24px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lty-wallet-icon {
  color: #c50248;
  background-color: #c5024830;
}
.lty-market-icon {
  background-color: #0099b030;
  color: #0099b0;
}
.lty-claims-icon {
  background-color: #0258de30;
  color: #0258de;
}
.lty-referral-icon {
  color: #ef9000;
  background-color: #fff1dc;
}
mat-expansion-panel.custom-collapsible-panel {
  width: 100%;
  border-radius: 12px;
  margin: 20px 0;
}
.custom-collapsible-panel .right-aligned-header > .mat-content {
  justify-content: space-between;
}

.custom-collapsible-panel .mat-content > mat-panel-title,
.mat-content > mat-panel-description {
  flex: 0 0 auto;
}

app-ui-kit-alternate-collapsible-panel {
  width: 100%;
  max-width: 909px;
}
.custom-collapsible-panel label {
  color: var(--gray700);
  font-size: 0.875rem;
  font-weight: 500;
}
.custom-collapsible-panel input {
  border: 1px solid var(--gray300);
  background-color: var(--gray50);
  color: var(--gray500);
  font-weight: 500;
  font-size: 0.875rem;
  padding: 12px;
  width: 100%;
  box-shadow: 0px 1px 2px 0px #1018280d;
  border-radius: 8px;
}
.custom-collapsible-panel .panel-content > div {
  margin-bottom: 16px;
  margin-top: 16px;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .custom-collapsible-panel mat-panel-description {
    display: none;
  }
}
.custom-panel-title {
  display: flex;
  align-items: center;
  gap: 8px;
}
.custom-panel-title > span {
  font-weight: 600;
  font-size: 1rem;
  color: var(--gray600);
}
.custom-panel-title > img {
  max-height: 20px;
  width: auto;
}
.summary-badge {
  padding: 2px 8px;
  border: 1px solid var(--gray100);
  color: var(--gray700);
  background-color: var(--gray50);
  border-radius: 16px;
}
.summary-badge.summary-badge-success {
  background-color: var(--success50);
  color: var(--success700);
  border-color: var(--success200);
}
#summary-info-elements {
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
}
#summary-info-elements > div:not(.row) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
#summary-info-elements > div.row {
  width: 100%;
}
.document-buttons {
  display: flex;
  justify-content: space-evenly;
}
.document-buttons > * {
  width: 40%;
}
@media screen and (max-width: 768px) {
  .document-buttons {
    flex-wrap: wrap;
    row-gap: 16px;
  }
  .document-buttons > * {
    width: 100%;
  }
}
.feedback_modal_content {
  padding: 20px;
}

.feedback_modal_content .star_container {
  display: inline-flex;
}

.feedback_modal_content .star {
  margin: 10px;
  cursor: pointer;
}

.feedback_modal_content .star_rated {
  color: #00197a;
}

.feedback_modal_content .star_unrated {
  color: #eff1f2;
}

.feedback_modal_content .textarea {
  background-color: #eff1f2;
  border-radius: 10px;
  border: none;
  padding: 15px;
  color: #494949;
}
.feedback_modal_body .lty-btn-primary {
  margin: auto;
}
.lty-btn-outline-gray {
  border-radius: 8px;
  padding: 10px 14px;
  border: 1px solid var(--gray300);
  background-color: white;
}
.reassurance-element {
  color: var(--blue1);
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
}

.reassurance-element span {
  margin-left: 8px;
}
@media screen and (min-width: 768px) {
  .mobile_only {
    display: none !important;
  }
}
@media screen and (max-width: 767px) {
  .mobile_only {
    display: block;
  }
  .mobile_hidden {
    display: none !important;
  }
  .dashboard-cards {
    flex-wrap: wrap;
  }
}

.form-element-title {
  font-size: 24px;
  font-weight: 500;
  color: var(--gray500);
  margin-bottom: 18px;
}
@media screen and (max-width: 991px) {
  .form-element-title {
    font-size: 14px;
    font-weight: 700;
  }
}
.insurance-form-container {
  height: 100%;
  width: 100%;
}
.insurance-form-container form {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
}
/* rules to adapt/fix old components with overhaul */
.insurance-form-container .form_page .back_btn {
  margin-right: auto;
}
.insurance-form-container .form_page .btn_next,
.insurance-form-container .form_page .insurance_logo {
  margin-right: auto;
  margin-left: auto;
}
.insurance-form-container .form_page .insurance_logo {
  display: flex;
}
.insurance-form-container .form_page,
.insurance-form-container .flow_form {
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
/* end */

.form-submit-buttons-container {
  background-color: transparent;
  padding-top: 12px;
}
.form-submit-buttons-container.cornered {
  position: fixed;
  right: 0;
  bottom: 0;
  padding-right: 24px;
  padding-bottom: 24px;
}
.form-submit-buttons {
  display: flex;
  gap: 8px;
}
.form-submit-buttons-container.inline {
  justify-content: center;
}
.form-submit-buttons.inline .lty-btn-primary {
  margin-left: 0 !important;
  margin-right: 0 !important;
  flex-grow: 1;
}
@media screen and (max-width: 767px) {
  .form-submit-buttons-container {
    width: 100%;
  }

  .form-submit-buttons {
    width: 100%;
    right: 0;
    padding-left: 14px;
    padding-right: 14px;
  }
  .form-submit-buttons button[type='submit'] {
    flex-grow: 1;
  }
}
.insurance-form-container form {
  padding-right: 16px;
  padding-left: 16px;
}

.mb-6 {
  margin-bottom: 6px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-48 {
  margin-bottom: 48px !important;
}

.mt-8 {
  margin-bottom: 8px !important;
}

.mt-10 {
  margin-bottom: 10px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-48 {
  margin-top: 48px !important;
}

@media screen and (min-width: 992px) {
  .mb-md-6 {
    margin-bottom: 6px !important;
  }

  .mb-md-8 {
    margin-bottom: 8px !important;
  }

  .mb-md-10 {
    margin-bottom: 10px !important;
  }

  .mb-md-12 {
    margin-bottom: 12px !important;
  }

  .mb-md-16 {
    margin-bottom: 16px !important;
  }

  .mb-md-20 {
    margin-bottom: 20px !important;
  }

  .mb-md-24 {
    margin-bottom: 24px !important;
  }

  .mb-md-32 {
    margin-bottom: 32px !important;
  }

  .mb-md-40 {
    margin-bottom: 40px !important;
  }

  .mb-md-48 {
    margin-bottom: 48px !important;
  }

  .mt-md-8 {
    margin-top: 8px !important;
  }

  .mt-md-10 {
    margin-top: 10px !important;
  }

  .mt-md-12 {
    margin-top: 12px !important;
  }

  .mt-md-16 {
    margin-top: 16px !important;
  }

  .mt-md-20 {
    margin-top: 20px !important;
  }

  .mt-md-24 {
    margin-top: 24px !important;
  }

  .mt-md-32 {
    margin-top: 32px !important;
  }

  .mt-md-40 {
    margin-top: 40px !important;
  }

  .mt-md-48 {
    margin-top: 48px !important;
  }
}
.rotate-180 {
  rotate: 180deg !important;
}
.filters-header {
  font-size: 16px;
  color: black;
  font-weight: 500;
}
.filters-element {
  margin-top: 16px;
  margin-bottom: 16px;
}
.filter-title {
  font-size: 14px;
  font-weight: 500;
  color: var(--gray600);
  margin-bottom: 16px;
}
.hidden {
  visibility: hidden;
}

@media screen and (max-width: 767px) {
  .submit-div-container {
    margin-top: 200px;
  }
  .submit-div {
    padding: 16px;
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    right: 0;
    margin-bottom: 0 !important;
  }
  .submit-div .lty-btn-primary {
    margin-bottom: 0 !important;
  }
  .help-text-submit-div .submit-div-container {
    margin-top: 0;
  }
  .help-text-submit-div {
    margin-bottom: 200px;
  }
}
@media screen and (min-width: 768px) {
  .submit-div .lty-btn-primary {
    margin-left: auto;
    margin-right: auto;
  }
}
.search_subtitle {
  text-align: center;
}
@media screen and (max-width: 991px) {
  .cky-btn-revisit-wrapper {
    display: none !important;
  }

  body.in-webview .cky-consent-container {
    visibility: hidden;
  }

  .cky-btn-revisit-wrapper body.in-webview .modal-content {
    max-height: min(100%, 85vh);
    overflow: scroll;
    margin-top: 50px;
  }
  body.in-webview .offcanvas-header {
    margin-top: 25px;
  }
}
.form-main-title {
  color: var(--gray500);
  font-size: 24px;
  line-height: 28px;
}
.form-subtitle {
  color: black;
  font-size: 18px;
  line-height: 24px;
}
button.empty-btn {
  border: none;
  background-color: transparent;
}
@media screen and (max-width: 991px) {
  .form-main-title {
    font-size: 14px;
    font-weight: 700;
  }
  .form-subtitle {
    font-size: 15px;
  }
}
.summary-title {
  font-size: 24px;
  text-align: start;
  color: var(--gray500);
  align-self: flex-start;
}
.member-summary-title {
  padding: 4px 12px;
  background-color: var(--gray50);
  border: 1px solid var(--gray100);
  border-radius: 16px;
  color: var(--gray700);
  font-size: 16px;
  text-align: start;
  align-self: flex-start;
}
.summary-terms-div {
  font-size: 14px;
  color: var(--gray700);
  padding: 12px 16px;
  border-radius: 16px;
  border: 1px solid #eff1f2;
  width: 100%;
}
.summary-terms-div a {
  color: var(--brand950);
  text-decoration: underline;
}
body.in-webview-old .lty_form_body .back_btn {
  top: 120px;
}
.in-webview-old .main-page {
  margin-top: 20px;
}
.summary-element-title {
  font-family: 'Neue Montreal';
}
.modal-backdrop {
  background-color: var(--brand950);
}
.lty-form .form-control,
.lty-form ngx-intl-tel-input > div {
  border: 1px solid #cbd5e1;
  box-shadow: 0px 1px 2px 0px #1018280d;
  border-radius: 8px;
  background-color: white;
}
.lty-form label,
.lty-form .form-group > label {
  color: var(--gray-700);
  font-family: 'Neue Montreal';
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 6px;
  line-height: 24px;
}
.lty-form .form-title {
  color: var(--gray950);
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}
.lty-form ngx-intl-tel-input > div > div,
.lty-form ngx-intl-tel-input > div > input {
  background-color: white !important;
}
.flow_form h1.form-title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: var(--gray500);
  margin-bottom: 16px;
}
.simple-card {
  border: 1px solid var(--gray200);
  border-radius: 16px;
  padding: 16px;
  gap: 8px;
}
.form-check-input:checked {
  background-color: var(--brand600);
  border-color: var(--brand600);
}
.form-container-card {
  display: flex;
  flex-direction: column;
  border: 1px #eff1f2 solid;
  border-radius: 16px;
  padding: 16px;
  box-shadow: 0px 1px 2px 0px #1018280d;
  gap: 16px;
}
.lty-form-group-animated {
  min-height: calc(100vh - 170px);
  scroll-behavior: smooth;
  scroll-margin-top: 70px;
}
.lty-form-group {
  margin-top: 32px;
}
.lty-form .lty-form-group label {
  font-family: 'Neue Montreal', sans-serif;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
}

.calendly-spinner{
  display: none;
}